import modules from "./_modules";
import nextSlide from './modules/nextSlide'




nextSlide();


modules();


