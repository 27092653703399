export default  () => {
    if(document.getElementsByClassName('building-item')){
        var mySliders = document.getElementsByClassName('desktop-photo-slider');
        var myBuildings = document.getElementsByClassName('building-item');

        $(myBuildings[0]).mouseenter(function() {
            mySliders[0].swiper.slideNext();
        });

        $(myBuildings[1]).mouseenter(function() {
            mySliders[1].swiper.slideNext();
        });

        $(myBuildings[2]).mouseenter(function() {
            mySliders[2].swiper.slideNext();
        });

        $(myBuildings[3]).mouseenter(function() {
            mySliders[3].swiper.slideNext();
        });

        $(myBuildings[4]).mouseenter(function() {
            mySliders[4].swiper.slideNext();
        });

        $(myBuildings[5]).mouseenter(function() {
            mySliders[5].swiper.slideNext();
        });

        $(myBuildings[6]).mouseenter(function() {
            mySliders[6].swiper.slideNext();
        });

        $(myBuildings[7]).mouseenter(function() {
            mySliders[7].swiper.slideNext();
        });

        $(myBuildings[8]).mouseenter(function() {
            mySliders[8].swiper.slideNext();
        });

        $(myBuildings[9]).mouseenter(function() {
            mySliders[9].swiper.slideNext();
        });

        $(myBuildings[10]).mouseenter(function() {
            mySliders[10].swiper.slideNext();
        });

        $(myBuildings[11]).mouseenter(function() {
            mySliders[11].swiper.slideNext();
        });

        $(myBuildings[12]).mouseenter(function() {
            mySliders[12].swiper.slideNext();
        });

        $(myBuildings[13]).mouseenter(function() {
            mySliders[13].swiper.slideNext();
        });

        $(myBuildings[14]).mouseenter(function() {
            mySliders[14].swiper.slideNext();
        });

        $(myBuildings[15]).mouseenter(function() {
            mySliders[15].swiper.slideNext();
        });

        $(myBuildings[16]).mouseenter(function() {
            mySliders[16].swiper.slideNext();
        });

        $(myBuildings[17]).mouseenter(function() {
            mySliders[17].swiper.slideNext();
        });

        $(myBuildings[18]).mouseenter(function() {
            mySliders[18].swiper.slideNext();
        });

        $(myBuildings[19]).mouseenter(function() {
            mySliders[19].swiper.slideNext();
        });

        $(myBuildings[20]).mouseenter(function() {
            mySliders[20].swiper.slideNext();
        });

        $(myBuildings[21]).mouseenter(function() {
            mySliders[21].swiper.slideNext();
        });

        $(myBuildings[22]).mouseenter(function() {
            mySliders[22].swiper.slideNext();
        });

        $(myBuildings[23]).mouseenter(function() {
            mySliders[23].swiper.slideNext();
        });

        $(myBuildings[24]).mouseenter(function() {
            mySliders[24].swiper.slideNext();
        });

        $(myBuildings[25]).mouseenter(function() {
            mySliders[25].swiper.slideNext();
        });

        $(myBuildings[26]).mouseenter(function() {
            mySliders[26].swiper.slideNext();
        });

        $(myBuildings[27]).mouseenter(function() {
            mySliders[27].swiper.slideNext();
        });

        $(myBuildings[28]).mouseenter(function() {
            mySliders[28].swiper.slideNext();
        });

        $(myBuildings[29]).mouseenter(function() {
            mySliders[29].swiper.slideNext();
        });

        $(myBuildings[30]).mouseenter(function() {
            mySliders[30].swiper.slideNext();
        });

        $(myBuildings[31]).mouseenter(function() {
            mySliders[4].swiper.slideNext();
        });

        $(myBuildings[32]).mouseenter(function() {
            mySliders[32].swiper.slideNext();
        });
    }
}

