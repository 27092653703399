import { Swiper, Navigation, Pagination, EffectFade, Autoplay } from "swiper/js/swiper.esm.js";

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

export default () => {
    var homeSlider = new Swiper('.top-image-slider', {
        effect: 'fade',
        loop: true,
        slidesPerView: 1,
        runCallbacksOnInit: true,
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slide-next',
            prevEl: '.slide-prev',
        },
    });

    

    var buildingSlider = new Swiper('.mobile-building-slider', {
        loop: true,
        slidesPerView: 1.2,
        spaceBetween: 10,
        runCallbacksOnInit: true,
        pagination: {
            el: '.building-pagination',
            clickable: true
        },
        
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    var placesSlider = new Swiper('.places-slide', {
        loop: true,
        navigation: {
          nextEl: '.next-arrow',
          prevEl: '.back-arrow',
        },
    });

    var placesSlider = new Swiper('.entertaining-slide', {
        loop: true,
        navigation: {
          nextEl: '.entert-next-arrow',
          prevEl: '.entert-back-arrow',
        },
    });


    var gallerySlider = new Swiper('.galleries-slider', {
        loop: true,
        slidesPerView: 1.3,
        spaceBetween: 20,
        pagination: {
            el: '.galleries-pagination',
            clickable: true
        },
        breakpoints: {
            // when window width is >= 320px
            720: {
                slidesPerView: 4,
                spaceBetween: 20,
                allowSlidePrev: false,
                allowSlideNext: false,
            },
        }
    });


    var photosSlider = new Swiper('.photos-slider', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: '.photos-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.photo-next-arrow',
            prevEl: '.photo-back-arrow',
        },
       
    });

    var evolutionSlider = new Swiper('.slider-zoom-evolution', {
        loop: true,
        navigation: {
            nextEl: '.slider-zoom-evolution .nextSlide',
            prevEl: '.slider-zoom-evolution .prevSlide',
        },
    });

    

    const mainPhotoSlider = new Swiper('.main-photo-gallery', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: '.main-gallery-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.gallery-next-arrow',
            prevEl: '.gallery-back-arrow',
        },
       
    });

    if(document.querySelector("#all-thumbs")) {
        $(window).on("load", function(){
            const allImagesFrames = document.querySelectorAll(".main-photo-gallery .swiper-slide img");
            let content = document.querySelector("#all-thumbs");
            var contentFrames = document.querySelector("#all-thumbs .flex-thumbs");
            var botoaFrames = document.querySelector(".see-call a");
    
            allImagesFrames.forEach((element, index) => {
                var node = document.createElement("li");
                var nodeImage = document.createElement("img");
                nodeImage.src =  element.src;
                node.classList.add("frame");
                node.appendChild(nodeImage);
                node.addEventListener("click", () => {
                    closeModalOpened();
                    mainPhotoSlider.slideTo(index, 500, );
                });
                contentFrames.appendChild(node);
            });
            
            botoaFrames.addEventListener("click", (event) => {
                this.event.preventDefault();
                let content = document.querySelector("#all-thumbs");
                content.classList.add("is-active");
            });
            
        });
        
        function closeModalOpened() {
            let content = document.querySelector("#all-thumbs");
            content.classList.remove("is-active");
        }
    }


    var badgesSlider = new Swiper('.badges-slider', {
        loop: true,
        slidesPerView: 2,
        pagination: {
          el: '.swiper-pagination',
        },
      });
   
    



    //sliders dos empreendimentos

    var buildingPhotoSlider1 = new Swiper('.building-photo-slider1' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination1',
        },
    });

    var buildingPhotoSlider2 = new Swiper('.building-photo-slider2' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination2',
        },
    });

    var buildingPhotoSlider3 = new Swiper('.building-photo-slider3' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination3',
        },
    });

    var buildingPhotoSlider4 = new Swiper('.building-photo-slider4' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination4',
        },
    });

    var buildingPhotoSlider5 = new Swiper('.building-photo-slider5' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination5',
        },
    });

    var buildingPhotoSlider6 = new Swiper('.building-photo-slider6' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination6',
        },
    });

    var buildingPhotoSlider7 = new Swiper('.building-photo-slider7' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination7',
        },
    });

    var buildingPhotoSlider8 = new Swiper('.building-photo-slider8' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination8',
        },
    });

    var buildingPhotoSlider9 = new Swiper('.building-photo-slider9' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination9',
        },
    });

    var buildingPhotoSlider10 = new Swiper('.building-photo-slider10' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination10',
        },
    });

    var buildingPhotoSlider11 = new Swiper('.building-photo-slider11' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination11',
        },
    });

    var buildingPhotoSlider12 = new Swiper('.building-photo-slider12' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination12',
        },
    });

    var buildingPhotoSlider13 = new Swiper('.building-photo-slider13' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination13',
        },
    });

    var buildingPhotoSlider14 = new Swiper('.building-photo-slider14' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination14',
        },
    });

    var buildingPhotoSlider15 = new Swiper('.building-photo-slider15' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination15',
        },
    });

    var buildingPhotoSlider16 = new Swiper('.building-photo-slider16' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination16',
        },
    });



    var buildingPhotoSlider17 = new Swiper('.building-photo-slider17' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination17',
        },
    });

    var buildingPhotoSlider18 = new Swiper('.building-photo-slider18' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination18',
        },
    });

    var buildingPhotoSlider19 = new Swiper('.building-photo-slider19' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination19',
        },
    });

    var buildingPhotoSlider20 = new Swiper('.building-photo-slider20' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination20',
        },
    });

    var buildingPhotoSlider21 = new Swiper('.building-photo-slider21' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination21',
        },
    });

    var buildingPhotoSlider22 = new Swiper('.building-photo-slider22' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination22',
        },
    });

    var buildingPhotoSlider23 = new Swiper('.building-photo-slider23' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination23',
        },
    });

    var buildingPhotoSlider24 = new Swiper('.building-photo-slider24' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination24',
        },
    });

    var buildingPhotoSlider25 = new Swiper('.building-photo-slider25' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination25',
        },
    });

    var buildingPhotoSlider26 = new Swiper('.building-photo-slider26' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination26',
        },
    });

    var buildingPhotoSlider27 = new Swiper('.building-photo-slider27' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination27',
        },
    });

    var buildingPhotoSlider28 = new Swiper('.building-photo-slider28' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination28',
        },
    });

    var buildingPhotoSlider29 = new Swiper('.building-photo-slider29' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination29',
        },
    });

    var buildingPhotoSlider30 = new Swiper('.building-photo-slider30' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination30',
        },
    });

    var buildingPhotoSlider31 = new Swiper('.building-photo-slider31' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination31',
        },
    });

    var buildingPhotoSlider32 = new Swiper('.building-photo-slider32' , {
        effect: 'fade',
        slidesPerView: 1,
        loop: true,
        pagination: {
            el: '.pagination32',
        },
    });

    
    var sliderSurroundings = new Swiper('.sliderSurroundings', {
        slidesPerView: 1,
        navigation: {
            nextEl: '.next-slideA',
            prevEl: '.prev-slideA',
        },
        fadeEffect: {
            crossFade: true
        }
    });
};
